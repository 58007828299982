@each $name, $color in $theme-colors {
  .tooltip-#{$name} {
    --#{$prefix}tooltip-bg: var(--#{$name});
    $text: color-contrast($color);

    @if $text == $white or $text == $color-contrast-light {
      --#{$prefix}tooltip-color: var(--white);
    } @else {
      --#{$prefix}tooltip-color: var(--black);
    }
  }
}

.popover {
  --#{$prefix}popover-bg: var(--background) !important;
  --#{$prefix}popover-body-color: var(--text) !important;
  --#{$prefix}popover-header-bg: var(--light) !important;
  --#{$prefix}popover-header-color: var(--black) !important;
  --#{$prefix}popover-border-color: var(--black) !important;
  --#{$prefix}popover-arrow-border: var(--black) !important;
}