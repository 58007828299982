@use 'variables/colorsDark' as *;
@use 'sass:map';
@use 'functions';

$theme-colors-dark: map.merge($theme-colors, functions.getThemeColors($custom-colors-dark));

.bg-white path[fill="white"], .bg-white path[fill="#fff"], .bg-white path[fill="#ffffff"] {
  fill: var(--not-so-dark);
}

* {
  transition: background .3s ease;
}

@include color-mode(dark) {
  body {
    @each $name, $values in $custom-colors-dark {
      @each $weight, $color in $values {
        $separator: '-';
        @if $weight == '' {
          $separator: '';
        }
        @if type-of($color) == 'map' {
          --#{map.get($color, 'alias')}: #{map.get($color, 'color')};
          --#{$name}#{$separator}#{$weight}: #{map.get($color, 'color')};
        } @else {
          --#{$name}#{$separator}#{$weight}: #{$color};
        }
      }
    }
    --#{$variable-prefix}body-bg: #{map.get($theme-colors-dark, 'background')};
    --#{$variable-prefix}body-color: #{map.get($theme-colors-dark, 'text')};
  }
}
