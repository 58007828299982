.country-select-input {
  display: none;

  &:checked + label {
    outline: 2px solid var(--primary);
  }
}

.country-select {
  border-radius: 10%;

  img {
    height: var(--size, 30px);
    width: var(--size, 30px);
  }
}

input[readonly] {
  cursor: not-allowed;
}

.last-save-wrapper.position-fixed {
  z-index: 10;
}

.form-container {
  border-top: 1px solid var(--bs-border-color);
  margin-top: 1rem;
  padding-top: 1rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}