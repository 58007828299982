@charset "UTF-8";
// File: main.scss
// Author: Tomáš Vojík <xvojik00@stud.fit.vutbr.cz>
// Main stylesheet that is compiled into /dist/main.css

// Font Awesome
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts/';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import 'variables/customVariables';

@import 'custom';

// BS Configuration
@import 'mixins';

// Utilities
//@import '~bootstrap/scss/utilities';

// BS Layout & components
@import '~bootstrap/scss/root';
@import 'darkMode';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/offcanvas';

// BS Helpers
@import '~bootstrap/scss/helpers';

// BS Utilities
@import '~bootstrap/scss/utilities/api';

// Core items
@import 'animations';

// Utilities

// Components
@import '~flatpickr';
@import 'components/datepicker';
@import 'components/inputs';
@import 'components/loaders';
@import 'components/offcanvas';
@import 'components/tooltip';
@import 'components/autocomplete';

// Pages

#modeSwitch {
  font-size: 1.3rem;
  height: 3rem;
  overflow: hidden;
  position: relative;
  width: 3rem;

  i {
    left: 50%;
    position: absolute;
    top: .8rem;
    transition: translate .3s ease;
  }

  &.btn-dark {
    .fa-moon {
      translate: 4rem;
    }

    .fa-sun {
      translate: -50%;
    }
  }

  &.btn-light {
    .fa-moon {
      translate: -50%;
    }

    .fa-sun {
      translate: -4rem;
    }
  }
}

header.navbar {
  > .container-fluid {
    display: grid;
    grid-template-areas: 'brand nav actions';
    grid-template-columns: auto 1fr auto;

    .navbar-brand {
      grid-area: brand;
    }

    .navbar-collapse {
      grid-area: nav;
    }

    .actions {
      grid-area: actions;
    }

    @include media-breakpoint-down(lg) {
      grid-template-areas: 'brand . actions' 'nav nav nav';
    }
  }
}

.hide-dark {
  display: inherit !important;
}

.hide-light {
  display: none !important;
}

@include color-mode(dark) {
  .hide-dark {
    display: none !important;
  }
  .hide-light {
    display: inherit !important;
  }
}